import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

//import { SelectiveStrategy } from '../shared/services/selective-strategy.service';
//import { CustomReuseStrategy } from './services/custom-reuse-strategy.service';

import { AuthGuard } from '../auth/services/auth-guard.service';

/** Imports for the application's components, services, and modules */
/** Lazy loaded modules will not be imported here. */
import { WidgetLayoutEditorComponent } from './widget-layout/widget-layout-editor.component';


import { PageNotFoundComponent } from './not-found/not-found.component';
import { HomComponent } from './hom/hom.component';

import { InvalidUserComponent } from './invalid-user/invalid-user.component';
import { RouteToCrmComponent } from './route-to-crm/route-to-crm.component';
import { LogOutComponent } from './logout/logout.component';
import { LoadingPageComponent } from '../shared/components';
import { FwMapContainerComponent, FwMapComponent } from '../fw/fw-shared/components';

//If you want the child routes of loadComponent routes to inherit data from their parent,
//specify the paramsInheritanceStrategy to always, which in v17 is now set to emptyOnly

const appRoutes: Routes = [
  { path: 'link/route-to-crm/:entityName/:entityId', component: RouteToCrmComponent },
  { path: 'route-to-crm/:entityName/:entityId/:childEntityName/:childEntityId', component: RouteToCrmComponent },
  { path: 'route-to-crm/:entityName/:entityId/:childEntityName/:childEntityId/:childLabel', component: RouteToCrmComponent },
  { path: 'route-to-crm/:entityName/:entityId/:installerName', component: RouteToCrmComponent },
  { path: 'dev', loadChildren: () => import('./dev/dev.module').then(m => m.DevModule), canActivate: [AuthGuard] },

  { path: 'widget-layout-editor/:id', component: WidgetLayoutEditorComponent, canActivate: [AuthGuard] },
  { path: 'admin-dash', loadChildren: () => import('./portals/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule), canActivate: [AuthGuard] }, /*REMOVE */
  { path: 'acctg-dash', loadChildren: () => import('./portals/acctg-dashboard/acctg-dashboard.module').then(m => m.AcctgDashboardModule), canActivate: [AuthGuard] }, /*REMOVE */
  { path: 'mgr-dash', loadChildren: () => import('./portals/manager-dashboard/manager-dashboard.module').then(m => m.ManagerDashboardModule), canActivate: [AuthGuard] },
  { path: 'user-dash', loadChildren: () => import('./portals/user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule), canActivate: [AuthGuard] },
  { path: 'warranty-dash', loadChildren: () => import('./portals/warranty-dashboard/warranty-dashboard.module').then(m => m.WarrantyDashboardModule), canActivate: [AuthGuard] },
  { path: 'utilities', loadChildren: () => import('./portals/utilities/utilities.module').then(m => m.UtilitiesModule), canActivate: [AuthGuard] },
  { path: 'compliance', loadChildren: () => import('./portals/compliance/compliance.module').then(m => m.ComplianceModule), canActivate: [AuthGuard] },
  { path: 'installer', loadChildren: () => import('./portals/installer/installer.module').then(m => m.InstallerModule), canActivate: [AuthGuard] },
  { path: 'project', loadChildren: () => import('./portals/project/project.module').then(m => m.ProjectModule), canActivate: [AuthGuard] },
  { path: 'app-admin', loadChildren: () => import('./portals/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule), canActivate: [AuthGuard] },
  { path: 'all-sms-convos', loadChildren: () => import('./sms/sms.module').then(m => m.SmsModule), canActivate: [AuthGuard] },

  { path: 'user-settings', loadChildren: () => import('./portals/user/user-settings/user-settings.module').then(m => m.UserSettingsModule), canActivate: [AuthGuard] },
  { path: 'user-jobs', loadChildren: () => import('./portals/user/user-jobs/user-jobs.module').then(m => m.UserJobsModule), canActivate: [AuthGuard] },
  { path: 'user-pending-changes', loadChildren: () => import('./portals/user/user-pending-changes/user-pending-changes.module').then(m => m.UserPendingChangesModule), canActivate: [AuthGuard] },
  { path: 'user-notifications', loadChildren: () => import('./portals/user/user-notifications/user-notifications.module').then(m => m.UserNotificationsModule), canActivate: [AuthGuard] },

  { path: 'hom', component: HomComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogOutComponent },
  { path: 'hom-map', component: FwMapComponent, outlet: 'modal' },

  { path: 'loading/:container', component: LoadingPageComponent },

  { path: 'invalid-user', component: InvalidUserComponent },
  { path: 'home', redirectTo: 'hom' },
  { path: '', redirectTo: '/hom', pathMatch: 'full' },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/hom' }
];

@NgModule({
  //Make sure you configure setupTestingRouter, canceledNavigationResolution,
  //paramsInheritanceStrategy, titleStrategy, urlUpdateStrategy, urlHandlingStrategy,
  //and malformedUriErrorHandler in provideRouter or RouterModule.forRoot
  //since these properties are now not part of the Router's public API

  imports: [
    RouterModule.forRoot(appRoutes,
      { scrollPositionRestoration: 'top', useHash: false, enableTracing: false }) //turn on enableTracing to monitor route info via console log anchorScrolling: 'disabled',  scrollPositionRestoration: 'enabled',
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  exports: [RouterModule]
})

export class AppRoutingModule { }
